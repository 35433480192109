import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const SecurityKeyControl = ({
  id,
  isInvalid,
  error,
  placeholder,
  handleBlur,
  handleChange,
}) => {
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const [fileName, setFileName] = React.useState('');
  let fileReader;

  const handleFileRead = (e) => {
    const content = fileReader.result;
    handleChange(content);
  }

  const handleFileChange = (e) => {
    setIsFirstLoad(false);

    if (!e.target.files.length) {
      handleChange('');
      return;
    }

    const [file] = e.target.files;

    setFileName(file.name);

    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  return (
    <div>
      <div className="d-flex justify-content-start align-items-center">
        <Form.File
          id={id}
          custom
          label={fileName || placeholder}
          accept=".pem"
          isInvalid={isInvalid}
          onBlur={handleBlur}
          onChange={handleFileChange}
        />

        {!isFirstLoad && !isInvalid && <FontAwesomeIcon icon={faCheckCircle} className="ml-2 text-success" />}
      </div>

      {isInvalid && <small className="text-danger d-block">{error}</small>}
    </div>
  );
};

SecurityKeyControl.defaultProps = {
  placeholder: '*.pem',
};

SecurityKeyControl.propTypes = {
  id: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,

  // Handle change will send back the content of the file
  handleChange: PropTypes.func.isRequired,
};

export default SecurityKeyControl;