import React from 'react';
import { Breadcrumb as BsBreadcrumb } from 'react-bootstrap';

const Breadcrumb = ({
  items = [],
}) => {
  const renderItem = (key, { text, ...props }) => (
    <BsBreadcrumb.Item key={key} {...props}>
      {text}
    </BsBreadcrumb.Item>
  );

  return (
    <BsBreadcrumb>
      {items.map((item, index) => renderItem(index, item))}
    </BsBreadcrumb>
  );
};

export default Breadcrumb;