import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ThemedSpinner } from './themed';
import './LoadingModal.scss';

const LoadingModal = ({
  show,
  text,
}) => {
  return (
    <Modal className="loader" show={show} centered={true}>
      <ThemedSpinner animation="border" className="loader__spinner" />
      {text && <div className="loader__text">{text}</div>}
    </Modal>
  );
};

LoadingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  text: PropTypes.string,
  onHide: PropTypes.func,
};

export default LoadingModal;
