import React from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingModal } from '../common/components';
import { useConnect, withFirebase } from '../common/providers';

const Connect = ({
  firebase,
  location,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const params = new URLSearchParams(location.search);
  const eid = params.get('eid');
  const req = params.get('req');
  const { setConnectValue } = useConnect();

  React.useEffect(() => {
    const tokenExchange = async () => {
      try {
        const result = await firebase.connect({ eid, req });

        setConnectValue({
          eid,
          req,
          brand: result.data,
        });
      } catch (err) {
        console.error(err);
        setError(err.message);
      }

      setLoading(false);
    };

    if (eid && req) {
      tokenExchange();
    }
  }, [firebase, eid, req, setConnectValue]);

  if (!eid || !req) {
    return <Redirect to="/error/400?msg=Invalid parameters" />;
  }

  if (loading) {
    return <LoadingModal show={true} text="Loading" />;
  }

  if (error) {
    return <Redirect to="/error/400" />;
  }

  return <Redirect to="/order" />;
};

export default withFirebase(Connect);
