const Keys = {
  EID_KEY: 'eid',
  REQ_KEY: 'req',
  SESSION_KEY: 'session',
  BRAND_KEY: 'brand',
};

export const clearBrand = () => localStorage.removeItem(Keys.BRAND_KEY);

export const clearNonBrandKeys = () => Object.keys(Keys).forEach(name => {
  if (!name.match(/brand/i)) {
    localStorage.removeItem(Keys[name]);
  }
});

export const getBrand = () => JSON.parse(localStorage.getItem(Keys.BRAND_KEY));

export const getEid = () => localStorage.getItem(Keys.EID_KEY);

export const getReq = () => localStorage.getItem(Keys.REQ_KEY);

export const getSession = () => JSON.parse(localStorage.getItem(Keys.SESSION_KEY));

export const setBrand = (brand) => localStorage.setItem(Keys.BRAND_KEY, JSON.stringify(brand));

export const setEid = (eid) => localStorage.setItem(Keys.EID_KEY, eid);

export const setReq = (req) => localStorage.setItem(Keys.REQ_KEY, req);

export const setSession = (session) => localStorage.setItem(Keys.SESSION_KEY, JSON.stringify(session));