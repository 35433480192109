import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useConnect } from '../providers';

const ConnectedRoute = ({
  redirectOnError = true,
  ...props
}) => {
  const { eid, req, session } = useConnect();

  if (!eid && !req && !session) {
    return <Redirect to="/error/401" />;
  }

  return <Route {...props} />;
};

export default ConnectedRoute;