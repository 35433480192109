import React from 'react';
import styled from 'styled-components';
import { useConnect } from '../common/providers';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ThemedHeaders, ThemedIcon } from '../common/components/themed';

const ThankYouPage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  text-align: center;
  padding: 1.4rem; 2rem;
`;

const Caption = styled.p`
  margin-bottom: 0;
`;

const Icon = styled(ThemedIcon)`
  margin: 2rem 0;
`;

const Small = styled.small`
  display: block;
`;

const ThankYou = () => {
  const { clearBrandValueWithoutUpdate, clearNonBrandValues } = useConnect();

  React.useEffect(() => {
    clearNonBrandValues();
    clearBrandValueWithoutUpdate();
  }, [clearBrandValueWithoutUpdate, clearNonBrandValues]);

  return (
    <ThankYouPage>
      <Content body>
        <ThemedHeaders.H1>You're all set!</ThemedHeaders.H1>
        <Caption>Thank you for using PATTi for mobile payments!</Caption>
        <Icon icon={faCheckCircle} size="3x" />
        <Small className="text-muted">You may safely close this window.</Small>
      </Content>
    </ThankYouPage>
  );
};

export default ThankYou;
