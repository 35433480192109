import React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Formik, Form as FormikForm } from 'formik';
import { withFirebase } from '../common/providers';
import toast from '../common/toast';

const Page = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div`
  width: 500px;
  padding: 0 1.5rem;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email'),
  password: Yup.string()
    .required('Password is required'),
});

const SignIn = ({
  firebase,
}) => {

  const handleSignIn = async ({ email, password }) => {
    try {
      await firebase.signInWithEmailAndPassword(email, password);
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };

  return (
    <Page>

      <Helmet>
        <title>PATTi | Admin</title>
      </Helmet>

      <Box>

        <Header>
          <h2>PATTi</h2>
          <p className="text-muted">Sign in to your admin account</p>
        </Header>

        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={SignInSchema}
          onSubmit={handleSignIn}
        >
          {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) =>

            <FormikForm>

              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoComplete="email"
                  name="email"
                  type="email"
                  autoFocus={true}
                  isInvalid={errors.email && !!touched.email}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  autoComplete="current-password"
                  name="password"
                  type="password"
                  isInvalid={errors.password && !!touched.password}
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>

              <Button
                block
                className="d-flex justify-content-center align-items-center"
                type="submit"
                variant="primary"
                disabled={isSubmitting}
              >
                Sign In
                {isSubmitting && 
                  <Spinner
                    className="ml-2"
                    variant="light"
                    animation="border"
                    size="sm"
                  />
                }
              </Button>

            </FormikForm>
          }
        </Formik>

      </Box>
    </Page>
  );
};

export default withFirebase(SignIn);