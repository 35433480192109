import React from 'react';
import AdminContainer from './AdminContainer';
import Enterprise from './Enterprise';
import EnterpriseList from './EnterpriseList';
import ProfitCenter from './ProfitCenter';
import ProfitCenterList from './ProfitCenterList';
import SystemSettings from './SystemSettings';
import { Route, Switch } from 'react-router-dom';

const AdminHome = ({
  match,
}) => {
  return (
    <Switch>
      <AdminContainer>
        <Route exact path={`${match.url}/`} component={EnterpriseList} />
        <Route exact path={`${match.url}/systemsettings`} component={SystemSettings} />
        <Route exact path={`${match.url}/enterprises/:enterpriseId`} component={ProfitCenterList} />
        <Route exact path={`${match.url}/enterprises/:enterpriseId/profitcenters`} component={ProfitCenterList} />
        <Route path={`${match.url}/enterprises/:enterpriseId/settings`} component={Enterprise} />
        <Route path={`${match.url}/enterprises/:enterpriseId/profitcenters/:profitCenterId`} component={ProfitCenter} />
      </AdminContainer>

    </Switch>
  );
};

export default AdminHome;