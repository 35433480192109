import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ConnectProvider, withAuth, ThemeProvider } from './common/providers';
import { AnonOnlyRoute, ConnectedRoute, ProtectedRoute } from './common/components';
import { ToastContainer } from 'react-toastify';
import { Error } from './error';
import { AdminHome, SignIn } from './admin';
import { Connect, PasswordConnect } from './auth';
import { Order, ThankYou } from './order';
import { Policy } from './policies';
import './App.scss';

function App({ isAuthLoading }) {
  if (isAuthLoading) {
    return null;
  }


  return (
    <Router>
      <ThemeProvider>

        <ToastContainer
          position="bottom-left"
          hideProgressBar={true}
          autoClose={2500}
          className="p-toast"
          closeButton={false}
          bodyClassName="p-toast__body"
          pauseOnFocusLoss={false}
        />

        <ConnectProvider>
          <Switch>
            <AnonOnlyRoute path="/admin/signin" component={SignIn} />
            <ProtectedRoute path="/admin" component={AdminHome} />
            <Route path="/connect" component={Connect} redirectToError={false} />
            <Route path="/passconnect" component={PasswordConnect} />
            <ConnectedRoute path="/order" component={Order} />
            <Route path="/thankyou" component={ThankYou} />
            <Route path="/error/:errorCode" component={Error} />
            <Route path="/policies/:policyId" component={Policy} />
            <Redirect exact path="/" to="/admin" />
            <Redirect path="*" to="/error/404" />
          </Switch>
        </ConnectProvider>

      </ThemeProvider>
    </Router>
  );
}

export default withAuth(App);
