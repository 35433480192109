import React from 'react';
import Pay from './Pay';
import OrderConfirm from './OrderConfirm';
import EmailReceipt from './EmailReceipt';
import { Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { SessionRoute, VerifiedRoute } from '../common/components';
import { useConnect, useTheme } from '../common/providers';
import './Order.scss';

/**
 * Parent page of order associated routes. This component
 * will fetch the branding and set the theme provider for
 * child components to use.
 */

const Order = ({
  match,
}) => {
  const { brand } = useConnect();
  const { setTheme } = useTheme();

  React.useEffect(() => {
    setTheme(brand);
  }, [brand, setTheme]);

  return (
    <div className="pub">

      <div className="pub__content">
        <Switch>
          <VerifiedRoute exact path={`${match.url}/`} component={OrderConfirm} />
          <SessionRoute path={`${match.url}/pay`} component={Pay} />
          <SessionRoute path={`${match.url}/email`} component={EmailReceipt} />
        </Switch>
      </div>

      <footer className="pub__footer">
        <Link to="/policies/privacy">Privacy</Link>
        <strong className="mx-2">&middot;</strong>
        <Link to="/policies/terms">Terms</Link>
      </footer>

    </div>
  );
};

export default Order;