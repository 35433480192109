import React from 'react';
import QuickAdd from './QuickAdd';
import toast from '../common/toast';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlus } from '@fortawesome/free-solid-svg-icons';
import { SettingsButton } from '../common/components';
import { withFirebase } from '../common/providers';
import './EnterpriseList.scss';

const Enterprises = ({
  firebase,
  history,
}) => {
  const [enterprises, setEnterprises] = React.useState([]);
  const [showQuickAdd, setShowQuickAdd] = React.useState(false);

  const handleAdded = (result) => {
    const { enterpriseId, id: profitCenterId } = result;
    setShowQuickAdd(false);
    history.push(`/admin/enterprises/${enterpriseId}/profitcenters/${profitCenterId}`);
  };

  React.useEffect(() => {
    const fetchEnterprises = async () => {
      try {
        const result = await firebase.getEnterprises();
        setEnterprises(result);
      } catch (err) {
        console.error(err);
        toast({ error: 'Failed to fetch enterprises' });
      }
    };

    fetchEnterprises();
  }, [firebase]);

  return (
    <Container className="py-4">

      <div className="text-right">
        <SettingsButton
          variant="primary"
          as={Link}
          to="/admin/systemsettings"
        >
          <FontAwesomeIcon icon={faCog} />
        </SettingsButton>
      </div>

      <QuickAdd
        show={showQuickAdd}
        onConfirm={handleAdded}
        onCancel={() => setShowQuickAdd(false)}
      />

      <Row>
        <Col md={3}>
          <div className="quickadd" onClick={() => setShowQuickAdd(true)}>
            <div>
              <h5>Quick Add</h5>
              <FontAwesomeIcon className="quickadd__icon" icon={faPlus} />
            </div>
          </div>
        </Col>

        {enterprises.map((ent, index) =>
          <Col md={3} key={index}>
            <Link to={`/admin/enterprises/${ent.id}`} className="box">
              <div>
                <h3 className="box__title">{ent.name}</h3>
                <div className="box__number">{ent.profitCenterCount || 0}</div>
              </div>
            </Link>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default withFirebase(Enterprises);