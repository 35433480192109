import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Error } from '../common/images';

const Page = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

const Content = styled.div`
  text-align: center;
`;

const Image = styled.img`
  width: 70%;
  margin-bottom: 1rem;
`;

const GenericError = ({
  code,
  name,
  message,
}) => (
  <Page>
    <Content>
      <Image src={Error} alt="Error" />
      <h1 className="display-3">{code}</h1>
      <h4>{name}</h4>
      {message && <p className="text-muted">{message}</p>}
    </Content>
  </Page>
);

GenericError.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  message: PropTypes.string,
};

export default GenericError;