import React from 'react';
import Firebase from './Firebase';

const FirebaseContext = React.createContext({ firebase: null });

export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {value => <Component {...props} {...value} />}
  </FirebaseContext.Consumer>
);

export const useFirebase = () => React.useContext(FirebaseContext);

const FirebaseProvider = ({ children }) => (
  <FirebaseContext.Provider value={{ firebase: new Firebase() }}>
    {children}
  </FirebaseContext.Provider>
);

export default FirebaseProvider;