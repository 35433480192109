import React from 'react';
import QuickAdd from './QuickAdd';
import toast from '../common/toast';
import { faCog, faExclamationTriangle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Breadcrumb, Loading, SecurityWarning, SettingsButton } from '../common/components';
import { withFirebase } from '../common/providers';

const ProfitCenterList = ({
  firebase,
  history,
  match,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [enterprise, setEnterprise] = React.useState();
  const [profitCenters, setProfitCenters] = React.useState([]);
  const [showAdd, setShowAdd] = React.useState(false);
  const { enterpriseId } = match.params;

  const handleAdd = (profitCenter) => {
    const { id } = profitCenter;
    history.push(`/admin/enterprises/${enterpriseId}/profitcenters/${id}`);
  };

  const renderConfigLink = (profitCenterId, text) => (
    <Link to={`/admin/enterprises/${enterpriseId}/profitcenters/${profitCenterId}`}>
      {text}
    </Link>
  );

  React.useEffect(() => {
    const fetchEnterprise = async () => {
      try {
        const ent = await firebase.getEnterprise(enterpriseId);
        setEnterprise(ent);
      } catch (err) {
        console.error(err);
        toast({ error: 'Failed to fetch profit centers!' });
      }
    };

    fetchEnterprise();
  }, [firebase, enterpriseId]);

  React.useEffect(() => {
    const fetchProfitCenters = async () => {
      try {
        const pcs = await firebase.getProfitCenters(enterpriseId);
        setProfitCenters(pcs);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    if (enterprise) {
      fetchProfitCenters();
    }
  }, [firebase, enterprise, enterpriseId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SecurityWarning enterpriseId={enterpriseId} securityEnabled={enterprise.configured.security} />

      <Container className="py-4">

        <Breadcrumb items={[
          { text: 'Enterprises', linkAs: Link, linkProps: { to: '/admin' } },
          { text: enterprise.name, active: true },
        ]}
        />

        <div className="mb-4 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="text-primary mr-2">{enterprise.name}</h1>

            <Button
              variant="primary"
              onClick={() => setShowAdd(true)}
              size="sm"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>

          <SettingsButton
            variant="link"
            as={Link}
            to={`/admin/enterprises/${enterpriseId}/settings`}
          >
            <FontAwesomeIcon icon={faCog} />
          </SettingsButton>
        </div>

        <Table hover>
          <thead>
            <tr>
              <th>Profit Center</th>
              <th>Property</th>
              <th>Terminal Id</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {profitCenters.map(pc =>

              <tr key={pc.id}>
                <td>
                  {renderConfigLink(pc.id, pc.name)}
                  {(!pc.configured.creditCard || !pc.configured.branding || !pc.configured.address || !pc.configured.phone) &&
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>There are configuration warnings</Tooltip>}
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-warning" />
                    </OverlayTrigger>
                  }
                </td>
                <td>{pc.propertyName}</td>
                <td>{pc.terminalId}</td>
                <td className="text-right">
                  {renderConfigLink(pc.id, 'Edit')}
                </td>
              </tr>

            )}
          </tbody>
        </Table>
      </Container>

      <QuickAdd
        show={showAdd}
        onCancel={() => setShowAdd(false)}
        onConfirm={handleAdd}
        enterpriseId={enterpriseId}
      />
    </>
  );
};

export default withFirebase(ProfitCenterList);