import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useConnect } from '../providers';

const SessionRoute = (props) => {
  const { session } = useConnect();

  if (!session) {
    return <Redirect to="/order" />;
  }

  return <Route {...props} />;
};

export default SessionRoute;