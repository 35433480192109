import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

const RemoveIcon = styled(FontAwesomeIcon)`
  color: var(--gray);
  cursor: pointer;

  &:hover {
    color: var(--gray-dark);
  }
`;

export const createHeadersFormValidationSchema = () => Yup.array().of(
  Yup.object().shape({
    key: Yup.string()
      .required('Key is required')
      .test({
        name: 'duplicate',
        message: 'Duplicate key',
        test: function(value) {
          // 0: this key val obj
          // 1: root obj
          const root = this.from[1]?.value || { headers: [] };

          // If cur and another key is same, consider duplicate
          const result = root.headers.filter(h => h.key?.toLowerCase() === value?.toLowerCase());

          return result.length < 2;
        },
      }),
    val: Yup.string().required('Value is required'),
  })
);

export default function HeadersForm({ fieldName, inline, values, errors, handleBlur, handleChange }) {
  // Support for stacked forms or inline forms
  const colSize = {
    label: inline ? 3 : 12,
    field: inline ? 6 : 12,
  };

  return (
    <Form.Group as={Row}>
      <Form.Label column sm={colSize.label}>
        Headers (optional)
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip>Add custom headers to requests.</Tooltip>}
        >
          <FontAwesomeIcon icon={faInfoCircle} className="ml-2 text-muted" />
        </OverlayTrigger>
      </Form.Label>

      <Col sm={colSize.field}>
        <FieldArray name="headers">
          {({ remove, push }) =>
            <div>
              {values[fieldName].map((keyval, index) =>
                <Row className="mb-3" key={`h_${index}`}>
                  <Col xs={11}>
                    <Row>
                      <Col>
                        <Form.Control
                          placeholder="key"
                          name={`${fieldName}.${index}.key`}
                          isInvalid={!!errors[fieldName] && !!errors[fieldName][index]}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={keyval.key}
                        />
                        <Form.Control.Feedback type="invalid">
                          {!!errors[fieldName] && errors[fieldName][index]?.key}
                        </Form.Control.Feedback>
                      </Col>

                      <Col>
                        <Form.Control
                          placeholder="value"
                          name={`${fieldName}.${index}.val`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={keyval.val}
                        />
                      </Col>

                    </Row>
                  </Col>

                  <Col xs={1} className="mt-2">
                    <RemoveIcon
                      icon={faTimes}
                      onClick={() => remove(index)}
                    />
                  </Col>
                </Row>
              )}

              <Button
                variant="light"
                size="sm"
                className="mt-1"
                onClick={() => push({ key: '', val: '' })}
              >
                Add Header
              </Button>

            </div>
          }
        </FieldArray>

      </Col>
    </Form.Group>

  );
}
