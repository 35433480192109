import React from 'react';
import * as Yup from 'yup';
import toast from '../common/toast';
import { Button, Col, Container, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form as FormikForm } from 'formik';
import { Breadcrumb, SecurityKeyControl } from '../common/components';
import { withFirebase } from '../common/providers';
import { Link } from 'react-router-dom';

const SecurityKeySchema = Yup.object().shape({
  privateKey: Yup.string()
    .required('Private key must be uploaded')
    .matches(/PRIVATE KEY/, 'Must be a private key'),
});

const SystemSettings = ({
  firebase,
}) => {
  const handlePrivateKeyUpdate = async ({ privateKey }) => {
    if (!window.confirm("I hope you know what you're doing. Are you really sure??")) {
      return;
    }

    try {
      await firebase.updateSystemPrivateKey(privateKey);
      toast({ success: 'Private key updated!' });
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };

  return (
    <Container className="my-4">

      <Breadcrumb items={[
        { text: 'Home', linkAs: Link, linkProps: { to: '/' } },
        { text: 'System Settings', active: true },
      ]}
      />

      <h1>System Settings</h1>
      <p className="text-muted">These settings apply to the entire infrastructure</p>

      <h4 className="text-danger font-weight-bolder mt-2">Danger Zone</h4>
      <div>

        <Formik
          initialValues={{ privateKey: '' }}
          validationSchema={SecurityKeySchema}
          onSubmit={handlePrivateKeyUpdate}
        >

          {({ errors, handleBlur, isSubmitting, setFieldValue, touched, values }) =>

            <FormikForm>

              <Form.Group as={Row} className="mb-0">
                <Form.Label column sm={3} className="text-danger">
                  Private Key

                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        If there are existing users, uploading a different private
                        key will invalidate all existing links.
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faExclamationCircle} className="text-muted ml-1" />
                  </OverlayTrigger>

                  <small className="d-block text-muted">
                    PATTi's private RSA key in PEM format designated for this environment
                  </small>
                </Form.Label>

                <Col sm={6}>
                  <div className="mb-2">
                    <SecurityKeyControl
                      id="privateKey"
                      error={errors.privateKey}
                      placeholder="ex: private.pem"
                      handleBlur={handleBlur}
                      handleChange={val => setFieldValue('privateKey', val)}
                      isInvalid={!!errors.privateKey && !!touched.privateKey}
                    />

                  </div>

                  <Button
                    className="d-flex justify-content-center align-items-center"
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}
                  >
                    Save {isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
                  </Button>

                </Col>
              </Form.Group>

            </FormikForm>

          }

        </Formik>
        
      </div>

    </Container>
  );
};

export default withFirebase(SystemSettings);
