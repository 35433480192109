import React from 'react';
import { Redirect } from 'react-router-dom';
import { withFirebase } from '../common/providers';
import { LoadingModal } from '../common/components';

const PasswordConnect = ({
  firebase,
  location
}) => {

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [redirectUrl, setRedirectUrl] = React.useState('');

  const params = new URLSearchParams(location.search);
  const password = params.get('p');
  const enterpriseId = params.get('e');
  const profitCenterId = params.get('pc');

  React.useEffect(() => {
    const assignRedirectUrl = async () => {
      try {
        const redirectUrl = (await firebase.passwordConnect({ password, enterpriseId, profitCenterId })).data;
        console.log(`Redirect url: ${redirectUrl}`);

        setRedirectUrl(redirectUrl);
      } catch (error) {
        console.error(error);
        setError(error.message);
      }

      setLoading(false);
    };

    assignRedirectUrl();
  }, [firebase, password, enterpriseId, profitCenterId]);

  if (!password) {
    return <Redirect to="/error/400?msg=Invalid parameters" />;
  }

  if (loading) {
    return <LoadingModal show={true} text="Loading" />;
  }

  if (error) {
    return <Redirect to="/error/400" />;
  }

  return <Redirect to={redirectUrl} />;
};

export default withFirebase(PasswordConnect);