import MarkdownIt from 'markdown-it';
import { Container, Navbar } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import * as policies from './policies';

const Policy = ({
  match
}) => {
  const { policyId } = match.params;
  const policy = policies[policyId];

  if (!policy) {
    return <Redirect to="/error/404" />;
  }

  const __html = new MarkdownIt().render(policy);

  return (
    <>
      <Navbar variant="dark" bg="primary">
        <Container>
          <Navbar.Brand>PATTi</Navbar.Brand>
        </Container>
      </Navbar>

      <Container className="py-4">
        <div dangerouslySetInnerHTML={{ __html }} />
      </Container>
    </>
  );
};

export default Policy;