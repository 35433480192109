import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useConnect } from '../providers';

const VerifiedRoute = ({
  ...props
}) => {
  const { eid, req, session } = useConnect();

  if (session) {
    return <Redirect to="/order/pay" />;
  }

  if (!eid || !req) {
    return <Redirect to="/error/401" />; 
  }

  return <Route {...props} />;
};

export default VerifiedRoute;
