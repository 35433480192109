import React from 'react';
import * as Cache from '../Cache';

export const ConnectContext = React.createContext({
  eid: null,
  req: null,
  session: null,
  brand: null,
  clearBrandValueWithoutUpdate: () => {},
  clearNonBrandValues: () => {},
  clearConnectValues: () => {},
  setConnectValue: () => {},
});

const ConnectProvider = ({ children }) => {
  const [connectValue, setConnectValue] = React.useState({
    eid: Cache.getEid() || null,
    req: Cache.getReq() || null,
    session: Cache.getSession() || null,
    brand: Cache.getBrand() || null,
  });

  const clearBrandValueWithoutUpdate = React.useCallback(() => {
    Cache.clearBrand();
  }, []);

  const clearNonBrandValues = React.useCallback(() => {

    // Clear non-brand keys from local cache.
    Cache.clearNonBrandKeys();

    setConnectValue(value => ({
      ...value,
      eid: null,
      req: null,
      session: null,
    }));
  }, []);

  const setValue = React.useCallback((value) => {
    setConnectValue(curValue => {
      const newValue = {
        ...curValue,
        ...value,
      };

      Cache.setEid(newValue.eid);
      Cache.setReq(newValue.req);
      Cache.setSession(newValue.session);
      Cache.setBrand(newValue.brand);

      return newValue;
    });
  }, [setConnectValue]);

  return (
    <ConnectContext.Provider value={{
      ...connectValue,
      clearBrandValueWithoutUpdate,
      clearNonBrandValues,
      setConnectValue: setValue,
    }}>
      {children}
    </ConnectContext.Provider>
  );
};

export const useConnect = () => React.useContext(ConnectContext);

export default ConnectProvider;