import React, { useEffect } from 'react';
import Numeral from 'numeral';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { ListGroup, Modal } from 'react-bootstrap';
import { ThemedButton, ThemedIcon } from '../common/components/themed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faCreativeCommonsNc } from '@fortawesome/free-brands-svg-icons'

const CURRENCY_FORMAT = '$0,0.00';

const CloseButton = styled(ThemedIcon)`
  cursor: pointer;
`;

const ModalTitle = styled.h3`
  color: ${props => props.theme.color};
  margin-bottom: 0;
`;

const TipItem = styled(ListGroup.Item)`
  background: ${props => props.selected ? `rgba(${props.theme.rgb.red},${props.theme.rgb.green},${props.theme.rgb.blue},.1)` : 'var(--white)'} !important;
  text-align: center;
  padding: 1rem .25rem;
`;

const TipPercent = styled.h5`
  color: ${props => props.selected ? props.theme.color : 'var(--gray-dark)'};
  margin-bottom: 0;
  font-weight: bold;
`;

const TipAmount = styled.div`
  color: ${props => props.selected ? props.theme.color : 'var(--gray)'};
  font-size: .8rem;
  margin-top: .33rem;
`;

const CustomInput = styled.input`
  background: var(--white);
  display: block;
  width: 100%;
  border: none;
  outline: none;
  text-align: center;
  font-size: 2.2rem;
  color: var(--gray-dark);
`;

const TipPrompt = ({
  amount,
  tip: tipAmount,
  showSuggestions,
  show,
  onConfirm,
  onCancel,
}) => {
  const [selectedTip, setSelectedTip] = React.useState(tipAmount);
  const [selectedTipPresetId, setSelectedTipPresetId] = React.useState();

  const OTHER_TIP_PRESET_ID = 3;
  const presets = [.15, .18, .20, 0].map((percent, index) => ({
    text: percent ? `${percent * 100}%` : 'Other',
    amount: +(percent * amount).toFixed(2),
    id: index,
  }));

  const tipInputRef = React.useRef();

  const mask = createNumberMask({
    prefix: '$',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalsSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: true,
  });

  useEffect(() => {
    // Autoselect the 'Other' tip option if no tip suggestions will be shown
    if (!showSuggestions) {
      handleTipOptionClicked(OTHER_TIP_PRESET_ID, 0);
    }
  }, [showSuggestions]);

  const handleTipOptionClicked = (tipPresetId, amount) => {
    setSelectedTipPresetId(tipPresetId);

    if (tipPresetId === OTHER_TIP_PRESET_ID) {
      setSelectedTip(null);
      // Autofocus into the tip amount input when 'Other' is selected
      tipInputRef.current.inputElement.focus();
    } else {
      setSelectedTip(amount);
    }
  };

  const handleTipAmountChanged = (value) => {
    // When the amount is changed manually change the selected tip preset option to 'Other' automatically
    setSelectedTipPresetId(OTHER_TIP_PRESET_ID);
    setSelectedTip(Numeral(value).value());
  };

  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Body className="p-4">

        <div className="d-flex justify-content-between align-items-center mb-4">
          <ModalTitle>Tip</ModalTitle>
          <CloseButton icon={faTimes} onClick={onCancel} />
        </div>

        {showSuggestions &&
          <ListGroup horizontal>
            {presets.map(p =>
              <TipItem
                action
                key={p.text}
                selected={(selectedTipPresetId === p.id)}
                onClick={() => handleTipOptionClicked(p.id, p.amount)}
              >
                <TipPercent selected={(selectedTipPresetId === p.id)}>{p.text}</TipPercent>
                {!!p.amount &&
                  <TipAmount selected={(selectedTipPresetId === p.id)}>
                    { Numeral(p.amount.toFixed(2)).format(CURRENCY_FORMAT) }
                  </TipAmount>
                }
              </TipItem>
            )}
          </ListGroup>
        }

        <div className="text-center my-3">
          <small className="text-muted">Amount</small>
          <MaskedInput
            ref={tipInputRef}
            mask={mask}
            value={selectedTip}
            onChange={e => handleTipAmountChanged(e.target.value)}
            render={(ref, props) => 
              <CustomInput ref={ref} {...props} inputMode="decimal" />
            }
          />
        </div>

        <ThemedButton block onClick={() => onConfirm(selectedTip ?? 0)}>
          Confirm
        </ThemedButton>

      </Modal.Body>
    </Modal>
  );
};

TipPrompt.propTypes = {
  amount: PropTypes.number.isRequired,
  tip: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TipPrompt;