import React from 'react';
import ContactStore from './ContactStore';
import GenericError from './GenericError';

const Errors = {
  '400': { name: 'Bad Request' },
  '401': { name: 'Unauthorized' },
  '404': { name: 'Not Found' },
  '500': { name: 'Internal Error' },
  '503': { name: 'Service Unavailable', component: ContactStore },
};

const Error = ({
  location,
  match,
}) => {
  const params = new URLSearchParams(location.search);
  const message = params.get('msg');
  const { errorCode: code } = match.params;

  const { name, component } = Errors[code] || { name: 'Error' };
  const ErrorComponent = component || GenericError;

  const props = { name, code, message };

  return <ErrorComponent {...props} />;
};

export default Error;