import React from 'react';
import styled from 'styled-components';
import toast from '../common/toast';
import { Alert, Container, ListGroup } from 'react-bootstrap';
import { ThemedListGroupItem, ThemedLogo, ThemedSpinner } from '../common/components/themed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faExclamationTriangle, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useConnect, useFirebase } from '../common/providers';

const ListGroupItem = styled(ThemedListGroupItem)`
  display: flex;
  align-items: flex-start;
`;

const DetailIcon = styled.div`
  width: 50px;
`;

const DetailContent = styled.div`
  flex-grow: 1;
`;

const ContactStore = ({
  message,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [profitCenter, setProfitCenter] = React.useState();
  const encodedAddress = encodeURI(`${profitCenter?.address},${profitCenter?.city},${profitCenter?.state} ${profitCenter?.zip}`);
  const alertMessage = message || 'An error occurred. Please contact store.';

  const { firebase } = useFirebase();
  const { eid, session } = useConnect();

  React.useEffect(() => {
    const fetchProfitCenter = async () => {
      try {
        const result = await firebase.getProfitCenterInfo(eid, session.sessionId);

        setProfitCenter(result.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        toast({ error: 'Could not get store info' });
      }
    };

    fetchProfitCenter();
  }, [firebase, eid, session.sessionId]);

  return (
    <Container>
      <div className="text-center">
        <ThemedLogo />
      </div>

      <Alert variant="warning" className="text-center">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning mr-2" />
        {alertMessage}
      </Alert>

      {loading &&
        <div className="text-center py-2">
          <ThemedSpinner animation="border" />
        </div>
      }

      {!loading && profitCenter &&
        <ListGroup>

          <ListGroupItem action disabled>
            <DetailIcon>
              <FontAwesomeIcon icon={faBuilding} />
            </DetailIcon>
            <DetailContent>{profitCenter.name}</DetailContent>
          </ListGroupItem>

          <ListGroupItem action href={`https://maps.google.com/maps/place/${encodedAddress}`}>
            <DetailIcon>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </DetailIcon>

            <DetailContent>
              <address className="mb-0">
                {profitCenter.address} <br />
                {profitCenter.city}, {profitCenter.state} {profitCenter.zip}
              </address>
            </DetailContent>
          </ListGroupItem>

          <ListGroupItem action href={`tel:${profitCenter.phone}`}>
            <DetailIcon>
              <FontAwesomeIcon icon={faPhone} />
            </DetailIcon>

            <DetailContent>{profitCenter.phone}</DetailContent>
          </ListGroupItem>

        </ListGroup>
      }
    </Container>
  );
};

export default ContactStore;