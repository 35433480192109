import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

/**
 * Warning shown when the enterprise security key (RSA public key) is missing.
 */

const SecurityWarning = ({ enterpriseId, securityEnabled }) => {
  if (securityEnabled) {
    return null;
  }

  return (
    <div className="bg-warning d-flex justify-content-start align-items-top">
      <Container className="py-3">
        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
        Enterprise security is <u>disabled</u>. Upload a security key in enterprise <Link to={`/admin/enterprises/${enterpriseId}/settings`}>settings</Link>.
      </Container>
    </div>
  );
};

SecurityWarning.propTypes = {
  enterpriseId: PropTypes.string.isRequired,
  securityEnabled: PropTypes.bool,
};

export default SecurityWarning;