import React from 'react';
import hexRgb from 'hex-rgb';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import * as Cache from '../Cache';
import styles from '../../_app.module.scss';

export const ThemeProviderContext = React.createContext({
  theme: {},
  setTheme: (theme) => {},
});

export const ThemeProvider = ({ children }) => {
  const brand = Cache.getBrand();

  const [theme, setTheme] = React.useState({
    color: brand?.color || styles.primary,
    fontFamily: brand?.fontFamily || styles.fontFamily,
    rgb: hexRgb(brand?.color || styles.primary),
    logo: brand?.logo || '', // Logo image path for brand specific images
  });

  const setBrandTheme = React.useCallback((newTheme) => {
    setTheme(value => {
      const color = value.color || styles.primary;
      const rgb = hexRgb(color);

      return {
        ...value,
        ...newTheme,
        rgb,
      };
    });
  }, []);

  return (
    <ThemeProviderContext.Provider value={{ theme, setTheme: setBrandTheme }}>
      <StyledThemeProvider theme={theme}>
        {children}
      </StyledThemeProvider>
    </ThemeProviderContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeProviderContext);
