import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Numeral from 'numeral';
import { Container } from 'react-bootstrap';
import { ThemedButton } from '../common/components/themed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Header = styled.div`
  background: ${props => props.theme.color};
  text-align: center;
  padding: 2rem 0;
`;

const HeaderContainer = styled(Container)`
  position: relative;
`;

const CancelButton = styled(ThemedButton)`
  position: absolute;
  top: 0;
  right: 1rem;
  padding: 0;
  width: 24px;
  height: 24px;
`;

const Balance = styled.h1`
  color: var(--white);
  font-weight: bolder;
`;

const BalanceLabel = styled.div`
  color: var(--white);
  font-size: .8rem;
`;

const BalanceHeader = ({
  balance,
  onCancel,
  children,
}) => {
  return (
    <Header>
      <HeaderContainer>
        <BalanceLabel>Balance</BalanceLabel>
        <Balance>{Numeral(balance).format('$0,0.00')}</Balance>
        {children}

        {onCancel &&
          <CancelButton outline="true" onClick={onCancel}>
            <FontAwesomeIcon icon={faTimes} />
          </CancelButton>
        }
      </HeaderContainer>
    </Header>
  );
};

BalanceHeader.propTypes = {
  balance: PropTypes.number.isRequired,
  onCancel: PropTypes.func,
};

export default BalanceHeader;