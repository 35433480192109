import app from 'firebase/app';
import config from './config';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const EMULATOR = {
  auth: {
    url: 'http://localhost:9099',
  },
  firestore: {
    host: 'localhost',
    port: 8080,
  },
  functions: {
    host: 'localhost',
    port: 5001,
  },
};

export default class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();

    if(process.env.NODE_ENV === 'development') {
      this.auth.useEmulator(EMULATOR.auth.url);
      this.db.useEmulator(EMULATOR.firestore.host, EMULATOR.firestore.port);
      this.functions.useEmulator(EMULATOR.functions.host, EMULATOR.functions.port);

      console.warn('Using local Firebase emulators');
      console.warn(`auth - ${EMULATOR.auth.url}`);
      console.warn(`firestore - ${EMULATOR.firestore.host}:${EMULATOR.firestore.port}`);
      console.warn(`functions - ${EMULATOR.functions.host}:${EMULATOR.functions.port}`);
    }
  }

  connect = (data) => this.functions.httpsCallable('connect')(data);

  passwordConnect = (data) => this.functions.httpsCallable('passwordConnect')(data);

  cancelSession = (data) => this.functions.httpsCallable('cancelSession')(data);

  createSession = (data) => this.functions.httpsCallable('createSession')(data);

  deleteProfitCenter = (enterpriseId, profitCenterId) => this
    .getProfitCentersRef(enterpriseId).doc(profitCenterId)
    .delete();

  emailReceipt = (data) => this.functions.httpsCallable('emailReceipt')(data);

  getEnterprise = (enterpriseId) => this
    .getEnterprisesRef().doc(enterpriseId)
    .get()
    .then(doc => doc.data());

  getEnterprises = () => this
    .getEnterprisesRef()
    .get()
    .then(this.toArray);

  getEnterprisesRef = () => this.db
    .collection('enterprises');
    
  getOrder = (data) => this.functions.httpsCallable('getOrder')(data);

  /**
   * Get profit center info using session (non-admin).
   * @param {string} eid Enterprise identifier.
   * @param {string} sessionId Session identifier.
   * @returns 
   */
  getProfitCenterInfo = (eid, sessionId) =>
    this.functions.httpsCallable('getProfitCenterInfo')({ eid, sessionId });

  getProfitCenter = (enterpriseId, profitCenterId) => this
    .getProfitCentersRef(enterpriseId).doc(profitCenterId)
    .get()
    .then(doc => doc.data());

  getProfitCenters = (enterpriseId, orderBy = 'name', sortDirection = 'asc') => this
    .getProfitCentersRef(enterpriseId)
    .orderBy(orderBy, sortDirection)
    .get()
    .then(this.toArray);

  getProfitCentersRef = (enterpriseId) => this
    .getEnterprisesRef().doc(enterpriseId)
    .collection('profitCenters');

  payInit = (data) => this.functions
    .httpsCallable('payInit')(data);

  payCharge = (data) => this.functions
    .httpsCallable('payCharge', { timeout: 120000 })(data); 

  refreshRegistration = (data) => this.functions
    .httpsCallable('refreshRegistration')(data);

  registerTerminal = (data) => this.functions
    .httpsCallable('registerTerminal')(data);

  signInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  updateEnterprise = (enterpriseId, enterprise) => this
    .getEnterprisesRef().doc(enterpriseId)
    .update({
      ...enterprise,
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    });

  updateEnterpriseSettings = (enterpriseId, settings) => {
    const newSettings = {
      ...settings,
      enterpriseId,
    };

    return this.functions.httpsCallable('updateEnterpriseSettings')(newSettings);
  }

  updateProfitCenter = (enterpriseId, profitCenterId, profitCenter) => {
    const newProfitCenter = {
      ...profitCenter,
      updatedAt: app.firestore.FieldValue.serverTimestamp(),
    };

    return this
      .getProfitCentersRef(enterpriseId).doc(profitCenterId)
      .update(newProfitCenter);
  };

  updateSystemPrivateKey = (privateKey) => this.functions
    .httpsCallable('updateSystemPrivateKey')({ privateKey });

  /**
   * Helpers
   */

   toArray = (snapshot) => snapshot.docs.map(doc => Object.assign({ id: doc.id }, doc.data()));
}