import React from 'react';
import { withFirebase } from './firebase';

const AuthContext = React.createContext();

export const withAuth = Component => props => (
  <AuthContext.Consumer>
    {value => <Component {...props} {...value} />}
  </AuthContext.Consumer>
);

const AuthProvider = ({ children, firebase }) => {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        setUser({
          id: authUser.uid,
          name: authUser.displayName || authUser.email,
          email: authUser.email,
          image: authUser.photoURL,
        });
      } else {
        setUser(null);
      }

      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [firebase]);

  const obj = {
    isAuthLoading: loading,
    user,
  };

  return (
    <AuthContext.Provider value={obj}>
      {children}
    </AuthContext.Provider>
  );
};

export default withFirebase(AuthProvider);