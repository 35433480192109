import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ApplePayMark, GooglePayMark } from '../../common/images';

const PaymentType = {
  apple: {
    name: 'Apple Pay',
    image: ApplePayMark,
  },
  google: {
    name: 'Google Pay',
    image: GooglePayMark,
  },
  cc: {
    name: 'Credit Card',
    icon: faCreditCard,
  },
};

const PaymentTypeContainer = styled.div`
  padding: 1rem 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  margin-bottom: 1rem;
  border-radius: .8rem;
  transition: background 500ms;

  &:hover {
    cursor: pointer;
    background: #f2f2f2;
  }
`;

const MarkContainer = styled.div`
  width: 80px;
`;

const PayMarkImage = styled.img`
  height: ${props => props.mark_type === 'google' ? '50px' : '30px' };
  margin: ${props => props.mark_type === 'google' ? '0' : '10px 0' };
  position: relative;
  left: ${props => props.mark_type === 'google' ? '-12px' : '0' };
`;

const MarkIcon = styled(FontAwesomeIcon)`
  font-size: 1.8rem;
  margin: 10px 0;
`;

const PaymentText = styled.span`
  margin-left: .5rem;
  font-weight: ${props => props.selected ? 'bold' : 'normal'};
`;

const SelectIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.color};
`;

const PaymentMethodList = ({
  types,
  onSelected,
}) => {
  const [selected, setSelected] = React.useState();

  const handleSelected = type => {
    setSelected(type);
    onSelected(type);
  };

  const renderPaymentType = type => {
    const paymentType = PaymentType[type];

    return (
      <PaymentTypeContainer key={type} onClick={() => handleSelected(type)}>
        <div className="d-flex align-items-center">
          <MarkContainer>
            {paymentType.image && <PayMarkImage src={paymentType.image} mark_type={type} />}
            {paymentType.icon && <MarkIcon icon={paymentType.icon} />}
          </MarkContainer>
          <PaymentText selected={selected === type}>{paymentType.name}</PaymentText>
        </div>

        <div className="d-flex justify-content-end align-items-center">
          {selected !== type && <SelectIcon icon={faCircle} />}
          {selected === type && <SelectIcon className="animate__animated animate__faster animate__zoomIn" icon={faCheckCircle} />}
        </div>
      </PaymentTypeContainer>
    );
  };

  return (
    <div>
      {types.map(type => renderPaymentType(type))}
    </div>
  );
};

PaymentMethodList.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.oneOf(['apple', 'google', 'cc'])
  ).isRequired,
  onSelected: PropTypes.func.isRequired,
};

export default PaymentMethodList;
