import React from 'react';
import PropTypes from 'prop-types';
import PaymentMethodList from './PaymentMethodList';
import styled from 'styled-components';
import meta from '../../../package.json';
import { useTheme } from 'styled-components';
import { Container, Form } from 'react-bootstrap';
import { useConnect, withFirebase } from '../../common/providers';
import { ThemedSpinner } from '../../common/components/themed';

const PaymentContainer = styled.div`
  & iframe {
    width: 100%;
    height: ${props => props.height}px;
  }
`;

const NameOnCardContainer = styled.div`
  font-family: sans-serif !important;
  padding: 0 .675rem;
`;

const NameOnCardLabel = styled.label`
  color: ${props => props.invalid ? props.theme.color : '#6c757d'};
`;

const NameOnCardInput = styled(Form.Control)`
  border-radius: .25rem;
  padding: .375rem .75rem;
  height: calc(2.25rem + 2px);
  outline: none;

  &:active,
  &:focus {
    border-color: ${props => props.theme.color};
    outline: none;
    box-shadow: none !important;
  }
`;

const NameOnCardFeedback = styled(Form.Control.Feedback)`
  color: ${props => props.theme.color};
`;

const HpcPayment = ({
  firebase,
  order,
  onProcessing,
  onComplete,
  onError,
}) => {
  const [working, setWorking] = React.useState(false);
  const [iframeSession, setIframeSession] = React.useState();
  const [height, setHeight] = React.useState(0);
  const [paymentType, setPaymentType] = React.useState();

  // Since HPC does not return name on card but require
  // it, this is to get input from our form
  const [ccNameOnCard, setCcNameOnCard] = React.useState('');

  const { iframe, sessionKey } = iframeSession || {};
  const { eid, session } = useConnect();
  const { balance, tip, sessionId } = session;
  const { color, fontFamily } = useTheme();
  const nameOnCardRef = React.useRef();

  const ApplePay = window.FreedomPay?.Apm?.ApplePay || { isSupported: () => false };
  const { userAgent } = navigator;

  const availableMethods = {
    google: !!userAgent.match(/android/i),
    apple: ApplePay.isSupported() && ApplePay.canMakePayments(),
    cc: true,
  };

  const styles = `
    .fp-apple-pay-button {
      padding: 10px 0;
      height: 40px;
      width: 100%;
    }
    label {
      color: #6c757d;
      font-family: ${fontFamily};
    }
    .valid label {
      color: #6c757d;
    }
    .invalid label {
      color: ${color};
    }
    button {
      background-color: ${color};
      border-color: ${color};
      color: #fff;
      padding: 10px 0;
      width: 100%;
      border-radius: 25px;
    }
    button:focus {
      background-color: ${color};
      border-color: ${color};
    }
    button:hover {
      background-color: ${color};
      border-color: ${color};
      color: #fff;
    }
    input:focus {
      box-shadow: none;
      border-color: ${color};
    }
    .valid input:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
    .valid input:not(:disabled) {
      border-color: #ced4da;
    }
    .valid input:not(:disabled):focus {
      box-shadow: none;
      border-color: #ced4da;
    }
    .invalid input:not(:disabled) {
      border-color: ${color};
    }
    .invalid input:not(:disabled):focus {
      box-shadow: none;
      border-color: ${color};
    }
    .validation-message.feedback {
      color: ${color};
    }
  `;

  const handlePayment = React.useCallback(async (payment) => {
    onProcessing();

    try {
      const payload = {
        ...payment,
        eid,
        order,
        tip,
        version: meta.version,
        sessionId,
      };

      const result = await firebase.payCharge(payload);

      onComplete(result);
    } catch (err) {
      console.error(err);
      onError(err);
    }
  }, [eid, firebase, onComplete, onError, onProcessing, order, sessionId, tip]);

  React.useEffect(() => {
    if (paymentType === 'cc') {
      setTimeout(() => nameOnCardRef.current?.focus(), 1000);
    }
  }, [paymentType]);

  React.useEffect(() => {
    const handleCreditCardMessage = (e) => {
      const message = e.data;
      const data = message.data;

      // These are messages from react dev tools
      if (!message.type) {
        return;
      }

      switch(message.type) {
        case 1:
        case 4:
          // No work needed
          break;

        case 2:
          setHeight(data.height);
          break;

        case 3:
          const attributes = data.attributes
            .reduce((catalog, keyval) => ({
              ...catalog,
              [keyval.Key]: keyval.Value,
            }), {});

          let nameOnCard = null;
          if (paymentType === 'cc') {

            nameOnCard = ccNameOnCard;

            if (!nameOnCard) {
              return;
            }
          }

          const {
            Name: name,
            CardIssuer: issuer1,
            MaskedCardNumber: cardNumber1,
            
            // This is what FreedomPay returns for ApplePay
            cardIssuer: issuer2,
            maskedCardNumber: cardNumber2,
            billingContact,
          } = attributes;

          // ApplePay
          if (billingContact) {
            const billing = JSON.parse(billingContact);
            const { familyName, givenName } = billing;
            nameOnCard = `${givenName} ${familyName}`;
          }

          const issuer = issuer1 || issuer2;
          const cardNumber = cardNumber1 || cardNumber2;
          const [paymentKey] = data.paymentKeys;

          handlePayment({
            nameOnCard: nameOnCard || name,
            paymentKey,
            issuer,
            cardNumber,
            sessionKey,
            type: paymentType,
            last4: cardNumber ? cardNumber.slice(cardNumber.length - 4) : 'XXXX',
          });

          break;
        
        default:
          console.warn(`Message type ${message.type} not supported`);
          break;
      }
    };

    window.addEventListener('message', handleCreditCardMessage);

    return () => window.removeEventListener('message', handleCreditCardMessage);
  }, [sessionKey, paymentType, handlePayment, ccNameOnCard]);

  React.useEffect(() => {
    const fetchIframe = async () => {
      try {
        const result = await firebase.payInit({
          eid,
          sessionId: session.sessionId,
          type: paymentType,
          total: balance + tip,
          version: meta.version,
          styles,
        });
        setIframeSession(result.data);
      } catch (err) {
        console.error(err);
        onError(err);
      }

      setWorking(false);
    }

    if (paymentType) {
      setWorking(true);
      fetchIframe();
    }
  }, [paymentType, firebase, balance, tip, styles, onError, eid, session.sessionId]);

  return (
    <Container className="my-4">
      <PaymentMethodList
        types={Object.keys(availableMethods).filter(key => !!availableMethods[key])}
        onSelected={type => setPaymentType(type)}
      />

      {working &&
        <div className="text-center my-4">
          <ThemedSpinner animation="border" />
        </div>
      }

      {!working &&
        <>
          {paymentType === 'cc' &&
            <NameOnCardContainer>
              <NameOnCardLabel invalid={!ccNameOnCard}>Name on Card</NameOnCardLabel>
              <NameOnCardInput
                name="ccNameOnCard"
                isInvalid={!ccNameOnCard}
                ref={nameOnCardRef}
                value={ccNameOnCard}
                onBlur={e => setCcNameOnCard(e.target.value)}
                onChange={e => setCcNameOnCard(e.target.value)}
              />
              <NameOnCardFeedback type="invalid">Name on card is required</NameOnCardFeedback>
            </NameOnCardContainer>
          }

          <PaymentContainer
            height={height}
            dangerouslySetInnerHTML={{ __html: iframe }}
          />
        </>
      }

    </Container>
  );
};

HpcPayment.propTypes = {
  order: PropTypes.object.isRequired,
  onProcessing: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withFirebase(HpcPayment);
