import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withAuth } from '../providers';

const ProtectedRoute = ({
  location,
  user,
  ...props
}) => {

  if (!user) {
    return <Redirect to={{ ...location, pathname: "/admin/signin" }} />;
  }

  return <Route {...props} />;
};

export default withAuth(ProtectedRoute);