import React from 'react';
import * as Yup from 'yup';
import toast from '../common/toast';
import { Button, Col, Container, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Breadcrumb, HeadersForm, createHeadersFormValidationSchema, Loading, SecurityWarning } from '../common/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form as FormikForm } from 'formik';
import { withFirebase } from '../common/providers';
import { faCheckCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const EnterpriseSettingsSchema = Yup.object().shape({
  securityKey: Yup.string()
    .required('Security key must be uploaded')
    .matches(/PUBLIC KEY/, 'Security key must be a public key'),
});

const HeadersSchema = Yup.object().shape({
  headers: createHeadersFormValidationSchema(),
})

const Enterprise = ({
  firebase,
  match,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [enterprise, setEnterprise] = React.useState();
  const [securityFileName, setSecurityFileName] = React.useState();
  const [refreshCount, setRefreshCount] = React.useState(0);
  const { enterpriseId } = match.params;
  let fileReader;

  const handleFileRead = (e) => {
    const content = fileReader.result;
    fileReader._setValue(content);
  }

  const handleSecurityFileChange = (e, setValue) => {
    if (!e.target.files.length) {
      setValue('');
      return;
    }

    const [file] = e.target.files;

    setSecurityFileName(file.name);

    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader._setValue = setValue;
    fileReader.readAsText(file);
  };

  const handleUpdateEnterpriseSecurity = async (values) => {
    try {
      await firebase.updateEnterpriseSettings(enterpriseId, values);
      setRefreshCount(refreshCount + 1);

      toast({ success: 'Settings updated!' });
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };

  const handleUpdateEnterprise = async (values) => {
    try {
      const headers = values.headers.reduce((acc, keyval) => ({
        ...acc,
        [keyval.key]: keyval.val,
      }), {});

      await firebase.updateEnterprise(enterpriseId, { ...values, headers });
      setRefreshCount(refreshCount + 1);

      toast({ success: 'Settings updated!' });
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };

  React.useEffect(() => {
    const fetchEnterprise = async () => {
      try {
        const ent = await firebase.getEnterprise(enterpriseId);
        setEnterprise(ent);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchEnterprise();
  }, [firebase, enterpriseId, refreshCount]);

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <SecurityWarning enterpriseId={enterpriseId} securityEnabled={enterprise.configured.security} />

      <Container className="py-4">

        <Breadcrumb items={[
          { text: 'Enterprises', linkAs: Link, linkProps: { to: '/admin' } },
          { text: enterprise.name, linkAs: Link, linkProps: { to: `/admin/enterprises/${enterpriseId}` } },
          { text: 'Settings', active: true },
        ]}
        />

        <h1 className="text-primary" title={enterpriseId}>{enterprise.name}</h1>

        <h4 className="font-weight-bolder mt-4 pt-2">
          Settings
        </h4>

        <Formik
          initialValues={{ securityKey: '' }}
          validationSchema={EnterpriseSettingsSchema}
          onSubmit={handleUpdateEnterpriseSecurity}
        >
          {({ errors, handleBlur, isSubmitting, setFieldValue, touched }) =>

            <FormikForm className="mb-4">

              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  Security Key

                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>This is typically the public key in PEM format.</Tooltip>}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2 text-muted" />
                  </OverlayTrigger>

                  {!enterprise.configured.security &&
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Security key needs to be uploaded</Tooltip>}
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning ml-2" />
                    </OverlayTrigger>
                  }
                </Form.Label>

                <Col sm={6}>
                  <div className="d-flex justify-content-start align-items-center">
                    <Form.File
                      id="securityKey"
                      custom
                      label={securityFileName || 'ex: public.pem'}
                      accept=".pem"
                      isInvalid={!!errors.securityKey}
                      onBlur={handleBlur}
                      onChange={e => handleSecurityFileChange(e, (val) => setFieldValue('securityKey', val))}
                    />

                    {!errors.securityKey && touched.securityKey && <FontAwesomeIcon icon={faCheckCircle} className="ml-2 text-success" />}
                  </div>

                  {errors.securityKey && <small className="text-danger d-block">{errors.securityKey}</small>}
                </Col>

              </Form.Group>

              <Col sm={{ span: 6, offset: 3 }}>
                <Button
                  className="d-flex justify-content-center align-items-center"
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting}
                >
                  Upload {isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
                </Button>
              </Col>

            </FormikForm>

          }
        </Formik>


        <Formik
          initialValues={{
            headers: Object
              .keys(enterprise.headers || {})
              .map(key => ({ key, val: (enterprise.headers || {})[key] }))
          }}
          validationSchema={HeadersSchema}
          onSubmit={handleUpdateEnterprise}
        >
          {({ errors, handleBlur, handleChange, isSubmitting, values }) =>

            <FormikForm>

              <HeadersForm
                errors={errors}
                fieldName="headers"
                handleBlur={handleBlur}
                handleChange={handleChange}
                inline={true}
                values={values}
              />

              <Col sm={{ span: 6, offset: 3 }}>
                <Button
                  className="d-flex justify-content-center align-items-center"
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting}
                >
                  Save {isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
                </Button>
              </Col>

            </FormikForm>

          }
        </Formik>
      </Container>

    </>
  );
};

export default withFirebase(Enterprise);