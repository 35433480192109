import React from 'react';
import BalanceHeader from './BalanceHeader';
import styled from 'styled-components';
import toast from '../common/toast';
import * as Yup from 'yup';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { ThemedButton, ThemedHeaders, ThemedFormControl } from '../common/components/themed';
import { useConnect, withFirebase } from '../common/providers';

const BottomButtonContainer = styled(Container)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 3.5rem;;
  background: var(--white);
`;

const Body = styled(Container)`
  padding-bottom: 8rem;
`;

const SkipButton = styled(Button)`
  border-radius: 50px;
  text-decoration: none !important;
  transition: background-color 500ms;

  &:hover {
    background-color: #eee;
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const EmailReceiptSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email'),
});

const PaidLabel = styled.div`
  border-radius: 1rem;
  background: var(--white);
  color: ${props => props.theme.color};
  display: inline-block;
  padding: .2rem .8rem;
  font-size: .9rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const EmailButton = styled(ThemedButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmailSpinner = styled(Spinner)`
  margin-left: .5rem;
`;

const EmailReceipt = ({ firebase }) => {
  const { eid, session, setConnectValue } = useConnect();

  const handleEmailReceipt = async ({ email }) => {
    try {
      await firebase.emailReceipt({ ...session, eid, email });
      toast({ success: 'Receipt sent!' });
    } catch (err) {
      console.error(err);
      toast({ error: err });
    }
  }

  const handleSkip = () => {
    setConnectValue({ session: null });
  };

  return (
    <>
      <BalanceHeader balance={0}>
        <div className="text-center">
          <PaidLabel>Paid</PaidLabel>
        </div>
      </BalanceHeader>

      <Body>
        <ThemedHeaders.H1 className="mt-2">Receipt</ThemedHeaders.H1>
        <p className="text-muted">Enter your email for your receipt</p>

        <Formik
          initialValues={{ email: '' }}
          validationSchema={EmailReceiptSchema}
          onSubmit={handleEmailReceipt}
        >
          {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) =>
            <FormikForm>

              <Form.Group>
                <ThemedFormControl
                  name="email"
                  autoFocus={true}
                  isInvalid={!!errors.email && !!touched.email}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>

              <BottomButtonContainer>
                <EmailButton block type="submit" disabled={isSubmitting}>
                  Email Receipt
                  {isSubmitting && <EmailSpinner animation="border" size="sm" />}
                </EmailButton>

                <SkipButton
                  block
                  type="button"
                  variant="link"
                  onClick={handleSkip}
                >
                  Done
                </SkipButton>
              </BottomButtonContainer>

            </FormikForm>
          }
        </Formik>

      </Body>
    </>
  );
};

export default withFirebase(EmailReceipt);
