import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuth, withFirebase } from '../common/providers';

const AdminNav = ({
  firebase,
  user,
}) => {
  return (
    <Navbar variant="dark" bg="dark" expand="lg">
      <Navbar.Brand as={Link} to="/admin">PATTi</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="admin-navbar-nav">
        <Nav className="ml-auto">
          <NavDropdown title={user.name} id="admin-nav-dropdown" alignRight>
            <NavDropdown.Item onClick={() => firebase.signOut()}>
              <span className="text-danger">Sign Out</span>
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default compose(
  withAuth,
  withFirebase,
)(AdminNav);