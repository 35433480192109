import React from 'react';
import styled from 'styled-components';
import toast from '../common/toast';
import { Badge, Button, Col, Container, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { Breadcrumb, Loading, SecurityWarning } from '../common/components';
import { withFirebase } from '../common/providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCheckCircle, faExclamationTriangle, faInfoCircle, faMapMarkerAlt, faPhone, faSync } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Code = styled.code`
  font-size: .8rem;
  padding: .2rem;
`;

const IconContainer = styled.div`
  position: relative;
  font-size: 1.3rem;
`;

const RefreshButton = styled.button`
  border: none;
  background: ${props => props.disabled ? 'rgba(0,0,0,.05)' : 'none'};
  padding: .5rem .8rem;
  border-radius: .5rem;
  margin-left: .5rem;
  transition: color 500ms, background 500ms;
  color: ${props => props.disabled ? 'var(--gray)' : 'var(--primary)'};

  &:hover {
    cursor: pointer;
    background: rgba(0,0,0,.05);
  }
`;

const DetailRow = styled.div`
  display: flex;
  color: var(--gray);
  margin-bottom: .25rem;
`;
const DetailIcon = styled.div`
  width: 30px;
`;
const DetailContent = styled.div`
  flex-grow: 1;
`;

const ProfitCenter = ({
  firebase,
  history,
  match,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [deleting, setDeleting] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const [enterprise, setEnterprise] = React.useState();
  const [profitCenter, setProfitCenter] = React.useState();
  const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);
  const { enterpriseId, profitCenterId } = match.params;

  const handleDelete = async () => {
    setDeleting(true);

    try {
      await firebase.deleteProfitCenter(enterpriseId, profitCenterId);
      redirectToEnterprise();
    } catch (err) {
      console.error(err);
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);

    try {
      const request = { enterpriseId, profitCenterId };
      const result = await firebase.refreshRegistration(request);
      setProfitCenter(result.data);

      toast({ success: 'Settings updated!' });
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }

    setRefreshing(false);
  };

  const handleUpdate = async (values) => {
    try {
      // Persist to a map for optimum data struct
      const headers = values.headers.reduce((acc, keyval) => ({
        ...acc,
        [keyval.key]: keyval.val,
      }), {});

      await firebase.updateProfitCenter(enterpriseId, profitCenterId, { ...values, headers });
      toast({ success: 'Settings updated!' });
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };

  const renderConfigured = () => (
    <IconContainer>
      <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
    </IconContainer>
  );

  const renderSettingKeys = (keys = []) => keys.map(val =>
    <Badge key={val} variant="light" className="mr-2">
      <Code>{val}</Code>
    </Badge>
  );

  const renderWarning = (message) => (
    <IconContainer>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip>{message}</Tooltip>}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" />
      </OverlayTrigger>
    </IconContainer>
  )

  const redirectToEnterprise = React.useCallback(() => {
    history.push(`/admin/enterprises/${enterpriseId}`)
  }, [history, enterpriseId]);

  React.useEffect(() => {
    const fetchEnterprise = async () => {
      try {
        const ent = await firebase.getEnterprise(enterpriseId);
        setEnterprise(ent);
      } catch (err) {
        console.error(err);
        toast({ error: 'Failed to fetch profit center!' });
      }
    };

    fetchEnterprise();
  }, [firebase, enterpriseId]);

  React.useEffect(() => {
    const fetchProfitCenter = async () => {
      try {
        const pc = await firebase.getProfitCenter(enterpriseId, profitCenterId);
        if (pc) {
          setProfitCenter(pc);
          setLoading(false);
        } else {
          redirectToEnterprise();
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (enterprise) {
      fetchProfitCenter();
    }
  }, [firebase, enterpriseId, enterprise, profitCenterId, redirectToEnterprise]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SecurityWarning enterpriseId={enterpriseId} securityEnabled={enterprise.configured.security} />

      <Container className="py-4">

        <Breadcrumb items={[
          { text: 'Enterprises', linkAs: Link, linkProps: { to: '/admin' } },
          { text: enterprise.name, linkAs: Link, linkProps: { to: `/admin/enterprises/${enterpriseId}` } },
          { text: profitCenter.name, active: true },
        ]}
        />

        <div className="d-flex justify-content-start align-items-center">
          <h1 className="text-primary" title={profitCenterId}>{profitCenter.name}</h1>
          <RefreshButton variant="default" onClick={handleRefresh} disabled={refreshing}>
            <FontAwesomeIcon icon={faSync} spin={refreshing} />
          </RefreshButton>
        </div>
        
        <DetailRow>
          <DetailIcon>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </DetailIcon>
          <DetailContent>
            {profitCenter.address}, {profitCenter.city}, {profitCenter.state} {profitCenter.zip}

            {!profitCenter.configured.address &&
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>Address is missing:&nbsp;
                    <strong>
                      {[
                        profitCenter.address ? null : 'street',
                        profitCenter.city ? null : 'city',
                        profitCenter.state ? null : 'state',
                        profitCenter.zip ? null : 'zip',
                      ].filter(field => !!field).join(', ')}
                    </strong>
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning ml-2" />
              </OverlayTrigger>
            }
          </DetailContent>
        </DetailRow>

        <DetailRow>
          <DetailIcon>
            <FontAwesomeIcon icon={faPhone} className="mr-2" />
          </DetailIcon>
          <DetailContent>
            {profitCenter.phone}

            {!profitCenter.configured.phone &&
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Phone number is missing</Tooltip>}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning ml-2" />
              </OverlayTrigger>

            }
          </DetailContent>
        </DetailRow>

        <DetailRow>
          <DetailIcon>
            <FontAwesomeIcon icon={faBuilding} />
          </DetailIcon>
          <DetailContent>
            {profitCenter.propertyName}
          </DetailContent>
        </DetailRow>

        <h4 className="font-weight-bolder mt-4 pt-2">Settings</h4>

        <Form.Group as={Row}>
          <Form.Label column sm={3}>Credit Card</Form.Label>
          <Col sm={9} className="d-flex align-items-center">
            {profitCenter.configured.creditCard && renderConfigured()}
            {!profitCenter.configured.creditCard && renderWarning((
              <span>The following fields need to be configured in <strong>Terminal Settings</strong>:
              {renderSettingKeys(['pt-hpc-store-id', 'pt-hpc-terminal-id', 'pt-hpc-es-key'])}
              </span>
            ))}
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={3}>Branding</Form.Label>
          <Col sm={9} className="d-flex align-items-center">
            {profitCenter.configured.branding && renderConfigured()}
            {!profitCenter.configured.branding && renderWarning((
              <span>The following fields need to be configured in <strong>Terminal Settings</strong>:
              {renderSettingKeys(['pt-brand-logo', 'pt-brand-color'])}
              </span>
            ))}
          </Col>
        </Form.Group>

        <Formik
          enableReinitialize={true}
          initialValues={{ redirectDomains: profitCenter.redirectDomains }}
          onSubmit={handleUpdate}
        >

          {({ errors, handleBlur, handleChange, isSubmitting, isValid, touched, values }) =>

            <FormikForm>

              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  Redirect Domains (optional)
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>For app integration, whitelist domains for PATTi to redirect to upon completion of payment.</Tooltip>}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2 text-muted" />
                  </OverlayTrigger>
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    name="redirectDomains"
                    as="textarea"
                    isInvalid={errors.redirectDomains && !!touched.redirectDomains}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.redirectDomains}
                  />

                  <Form.Control.Feedback type="invalid">{errors.redirectDomains}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Row>
                <Col sm={{ span: 6, offset: 3 }}>
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting || !isValid}
                  >
                    Update {isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
                  </Button>
                </Col>
              </Row>

            </FormikForm>
          }
          
        </Formik>

        <h4 className="font-weight-bolder text-danger mt-4">Danger Zone</h4>
        <Form.Group as={Row}>
          <Form.Label column sm={3}>
            <h6 className="text-danger">Delete Profit Center</h6>
            <small>This will delete all data associated with the profit center.</small>
          </Form.Label>

          <Col sm={6}>
            <Button
              variant="outline-danger"
              onClick={() => setShowDeletePrompt(true)}
            >
              Delete
            </Button>
          </Col>
        </Form.Group>

      </Container>

      <Modal show={showDeletePrompt} onHide={() => setShowDeletePrompt(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Profit Center</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to permanently delete this profit center?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => setShowDeletePrompt(false)}
          >
            Cancel
          </Button>

          <Button
            className="d-flex justify-content-center align-items-center"
            variant="danger"
            onClick={handleDelete}
          >
            Yes, I'm sure {deleting && <Spinner animation="border" size="sm" className="ml-2" />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withFirebase(ProfitCenter);