import React from 'react';
import AdminNav from './AdminNav';

const AdminContainer = ({
  children
}) => (
  <>
    <AdminNav />
    {children}
  </>
);

export default AdminContainer;