import React from 'react';
import Numeral from 'numeral';
import styled from 'styled-components';
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { useConnect, withFirebase } from '../common/providers';
import { ThemedButton, ThemedLogo, ThemedSpinner } from '../common/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const Content = styled(Container)`
  margin-bottom: 2.9rem;
`;

const BottomButtonContainer = styled(Container)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 3.8rem;
  background: var(--white);
`;

const ContinueButton = styled(ThemedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Locked = styled.span`
  text-decoration: underline dotted;
  cursor: help;
`;

const Quantity = styled.span`
  font-size: .9rem;
  color: var(--gray);
`;

const Balance = styled.h1`
  color: ${props => props.theme.color};
  font-weight: bold;
  margin-bottom: 0;
`;

const Item = styled.div`
  background: #fafafa;
  padding: 1.25rem;
  border-radius: 1rem;
  margin-bottom: .8rem;
`;

const ItemName = styled.span`
  margin-right: .5rem;
`;

const PaidInFull = styled.div`
  text-align: center;
  color: red;
  border: 4px solid red;
  padding: .5rem 1rem;
  text-transform: uppercase;
  font-weight: bold;
  transform: rotate(-12deg);
  border-radius: 10px;
  font-size: 1.5rem;

  &:after {
    content: 'Paid in full';
  }
`;

const OrderConfirm = ({
  firebase,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [working, setWorking] = React.useState(false);
  const [order, setOrder] = React.useState();
  const { eid, req, setConnectValue } = useConnect();

  const handleNewSession = async () => {
    setWorking(true);

    try {
      const session = await firebase.createSession({ eid, req });
      setConnectValue({ session: session.data });
    } catch (err) {
      console.error(err);
      setWorking(false);
    }
  };

  React.useEffect(() => {
    const fetchOrder = async () => {
      try {
        const result = await firebase.getOrder({ eid, req });
        setOrder(result.data);
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    };

    fetchOrder();
  }, [firebase, eid, req]);

  if (loading) {
    return (
      <div className="text-center p-4">
        <ThemedSpinner animation="border" />
      </div>
    );
  }

  if (!order) {
    return <Redirect to="/error/404" />;
  }

  return (
    <Content>
      <div className="text-center">
        <ThemedLogo />
        <div className="mb-4">
          <Balance>{Numeral(order.balance).format('$0,0.00')}</Balance>
          <div className="text-muted">Balance</div>
        </div>
      </div>

      <div className="pb-4">
        {Object.keys(order.itemGroups).map(key => {
          const itemGroup = order.itemGroups[key];

          return (
            <Item body key={key}>
              <ItemName>{itemGroup.name}</ItemName>
              <Quantity>x{itemGroup.quantity}</Quantity>
            </Item>
          );
        })}
      </div>

        {!!order.balance &&
          <BottomButtonContainer>
            <ContinueButton block onClick={handleNewSession}>
              Continue to Pay
              {working && <ThemedSpinner variant="light" animation="border" size="sm" className="ml-2" />}
            </ContinueButton>
          </BottomButtonContainer>
        }

        {!order.balance &&
          <div className="d-flex justify-content-center w-100 mb-4 pb-4">
            <PaidInFull />
          </div>
        }
    </Content>
  );
};

export default withFirebase(OrderConfirm);