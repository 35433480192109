import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import toast from '../common/toast';
import { Button, Form, Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { withFirebase } from '../common/providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTv } from '@fortawesome/free-solid-svg-icons';
import { HeadersForm, createHeadersFormValidationSchema, Loading } from '../common/components';

const SmallTooltip = styled(Tooltip)`
  font-size: .8rem;
  text-align: left;
`;

const QuickAddSchema = Yup.object().shape({
  apiUrl: Yup.string()
    .required('Api url is required')
    .matches(/(http|https):\/\/.*/, 'Invalid url (ex: https://posapi.com)')
    .matches(/.*[^/]$/, 'Trailing slash not allowed'),
  terminalKey: Yup.string()
    .required('Terminal key is required'),
  redirectDomains: Yup.string(),
  headers: createHeadersFormValidationSchema(),
});

const QuickAdd = ({
  firebase,
  show,
  enterpriseId,
  onConfirm,
  onCancel,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [enterprise, setEnterprise] = React.useState();
  const apiUrlRef = React.useRef();

  const handleAdd = async (values) => {
    try {
      const headers = values.headers.reduce((acc, keyval) => ({
        ...acc,
        [keyval.key]: keyval.val,
      }), {});

      const result = await firebase.registerTerminal({ ...values, headers });
      onConfirm(result.data);
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };

  React.useEffect(() => {
    const fetchEnterprise = async () => {
      try {
        const enterprise = await firebase.getEnterprise(enterpriseId);
        setEnterprise(enterprise);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    if (enterpriseId) {
      fetchEnterprise();
    } else {
      setLoading(false);
    }
  }, [enterpriseId, firebase]);

  return (
    <Modal
      show={show}
      onEntered={() => apiUrlRef.current.focus()}
      onHide={onCancel}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          apiUrl: '',
          terminalKey: '',
          redirectDomains: '',
          headers: Object.keys(enterprise?.headers || {}).map(key => ({
            key,
            val: (enterprise.headers || {})[key],
          })),
        }}
        validationSchema={QuickAddSchema}
        onSubmit={handleAdd}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, isValid, touched, values }) =>
          <FormikForm>

            <Modal.Header closeButton className="align-items-center">
              <div className="d-flex justify-content-start align-items-center">
                <Modal.Title>Quick Add</Modal.Title>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <SmallTooltip>
                      Organization information will be added automatically.
                    </SmallTooltip>
                  }
                >
                  <FontAwesomeIcon className="text-muted ml-2" icon={faInfoCircle} />
                </OverlayTrigger>

              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <FontAwesomeIcon icon={faTv} size="10x" className="py-4 text-primary" />
              </div>

              <Form.Group>
                <Form.Label>API Url</Form.Label>
                <Form.Control
                  name="apiUrl"
                  type="url"
                  ref={apiUrlRef}
                  isInvalid={errors.apiUrl && !!touched.apiUrl}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.apiUrl}
                />
                <Form.Control.Feedback type="invalid">{errors.apiUrl}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Terminal Key</Form.Label>
                <Form.Control
                  name="terminalKey"
                  type="password"
                  isInvalid={errors.terminalKey && !!touched.terminalKey}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.terminalKey}
                />
                <Form.Control.Feedback type="invalid">{errors.terminalKey}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Redirect Domains (optional)
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        For app redirection, whitelist domains with new line.
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-muted" />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  name="redirectDomains"
                  as="textarea"
                  isInvalid={errors.redirectDomains && !!touched.redirectDomains}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.redirectDomains}
                />
                <Form.Control.Feedback type="invalid">{errors.redirectDomains}</Form.Control.Feedback>
              </Form.Group>

              {loading && <Loading />}
              {!loading && (
                <HeadersForm
                  fieldName="headers"
                  errors={errors}
                  values={values}
                  inline={false}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              )}

            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="link"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                className="d-flex justify-content-center align-items-center"
                type="submit"
                variant="primary"
                disabled={loading || isSubmitting || !isValid}
              >
                Register
                {isSubmitting &&
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                    className="ml-2" 
                  />
                }
              </Button>
            </Modal.Footer>

          </FormikForm>
        }
      </Formik>
    </Modal>
  );
};

QuickAdd.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withFirebase(QuickAdd);