import React from 'react';
import styled from 'styled-components';
import Numeral from 'numeral';
import BalanceHeader from './BalanceHeader';
import TipPrompt from './TipPrompt';
import HpcPayment from './payments/HpcPayment';
import toast from '../common/toast';
import { Button, Modal } from 'react-bootstrap';
import { useConnect, withFirebase } from '../common/providers';
import { LoadingModal } from '../common/components';
import { ThemedButton, ThemedSpinner } from '../common/components/themed';

const CancelConfirmButton = styled(ThemedButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: .33rem;
`;
const ModalTitle = styled.h3`
  color: ${props => props.theme.color};
`;

const Pay = ({
  firebase,
  history,
}) => {
  const { session, eid, req, setConnectValue } = useConnect();

  const [loading, setLoading] = React.useState(true);
  const [workingText, setWorkingText] = React.useState();
  const [cancelling, setCancelling] = React.useState(false);
  const [working, setWorking] = React.useState(false);
  const [showCancel, setShowCancel] = React.useState(false);
  const [tip, setTip] = React.useState(session.tip || 0);
  const [showTip, setShowTip] = React.useState(tip === 0);
  const [order, setOrder] = React.useState();
  const { balance, showTipSuggestions } = session;

  const handleCancel = async () => {
    setCancelling(true);

    try {
      await firebase.cancelSession({
        ...session,
        eid,
      });

      history.push('/thankyou');
    } catch (err) {
      console.error(err);
      setCancelling(false);
    }
  };

  const handleProcessing = React.useCallback(() => {
    setWorkingText('Processing');
    setWorking(true)
  }, []);

  const handleProcessingComplete = React.useCallback(() => {
    toast({ success: "Payment processed!" });
    history.push('/order/email');
  }, [history]);

  const handleProcessingError = React.useCallback((err) => {
    const code = err.code;

    switch (code) {
      case 'failed-precondition':
        setConnectValue({ session: null });
        break;

      case 'aborted':
        history.push('/error/503?msg=Something went wrong with the payment. See a store representative.');
        break;

      default:
        toast({ error: err.message || err });
        setWorking(false);
    }
  }, [history, setConnectValue]);

  const handleTip = (selectedTip) => {
    setConnectValue({
      session: {
        ...session,
        tip: selectedTip,
      },
    });
    setTip(selectedTip);
    setShowTip(false);
  };

  React.useEffect(() => {
    const fetchOrder = async () => {
      try {
        const { data: order } = await firebase.getOrder({ eid, req });

        setConnectValue(val => ({
          session: {
            ...val.session,
            balance: order.balance,
          },
        }));
        setOrder(order);
      } catch (err) {
        console.error(err);
        toast({ error: "Something went wrong, try again in a bit." });
      }

      setLoading(false);
    };

    fetchOrder();
  }, [firebase, eid, req, setConnectValue]);

  return (
    <>
      <BalanceHeader balance={balance} onCancel={() => setShowCancel(true)}>
        <ThemedButton outline="true" size="sm" onClick={() => setShowTip(true)}>
          {!!tip && `+${Numeral(tip).format('$0,0.00')}`}
          {!tip && '+Tip'}
        </ThemedButton>

      </BalanceHeader>


      {loading &&
        <div className="text-center mt-4">
          <ThemedSpinner animation="border" />
        </div>
      }

      {!loading &&
        <HpcPayment
          order={order}
          total={balance}
          tip={tip}
          onProcessing={handleProcessing}
          onComplete={handleProcessingComplete}
          onError={handleProcessingError}
        />
      }

      <Modal show={showCancel} onHide={() => setShowCancel(false)}>
        <Modal.Body className="p-4">
          <ModalTitle>Cancel Payment</ModalTitle>
          Are you sure you want to cancel?

          <div className="d-flex justify-content-end mt-4">
            <Button variant="link" onClick={() => setShowCancel(false)}>Cancel</Button>
            <CancelConfirmButton onClick={handleCancel}>
              Confirm {cancelling && <ThemedSpinner variant="light" animation="border" size="sm" className="ml-2" />}
            </CancelConfirmButton>
          </div>
        </Modal.Body>
      </Modal>

      <LoadingModal show={working} text={workingText} />

      <TipPrompt
        amount={balance}
        tip={tip}
        show={showTip}
        showSuggestions={showTipSuggestions}
        onConfirm={handleTip}
        onCancel={() => handleTip(session.tip || 0)}
      />
    </>
  );
};

export default withFirebase(Pay);