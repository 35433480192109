import styled from 'styled-components';
import { toast as toastify } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const ToastBody = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.theme.color};
  height: 100%;
  width: 100%;
  padding: 1rem;
  color: var(--white);
`;

const ToastMessage = styled.span`
  margin-left: .5rem;
`;

const toast = ({ success, error }) => toastify(
  <ToastBody>
    <FontAwesomeIcon icon={ success ? faCheckCircle : faTimesCircle } />
    <ToastMessage>{success || error}</ToastMessage>
  </ToastBody>
);

export default toast;