import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, ListGroup, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

export const ThemedButton = styled(Button)`
  color: ${props => props.outline ? props.theme.color : 'var(--white)'};
  border-radius: 50px;
  background: ${props => props.outline ? 'var(--white)' : props.theme.color};
  border: 1px solid ${props => props.theme.color};
  transition: filter 300ms, box-shadow 200ms, color 300ms;
  -webkit-transition: -webkit-filter 300ms, box-shadow 200ms, color 300ms;

  &:hover {
    background: ${props => props.theme.color};
    filter: brightness(90%);
    color: var(--white);
    border: 1px solid ${props => props.theme.color} !important;
  }

  &:focus {
    background: ${props => props.theme.color} !important;
    outline: none !important;
    border: 1px solid ${props => props.theme.color} !important;
    filter: brightness(80%);
    box-shadow: 0 0 0 .2rem rgba(${props => props.theme.rgb.red}, ${props => props.theme.rgb.green}, ${props => props.theme.rgb.blue}, .5) !important;
  }

  &:active {
    border-color: ${props => props.theme.color} !important;
    background: ${props => props.theme.color} !important;
    filter: brightness(70%);
  }

  &:disabled {
    border: 1px solid ${props => props.theme.color} !important;
    background-color: ${props => props.theme.color} !important;
  }

`;

export const ThemedFormControl = styled(Form.Control)`
  &:active,
  &:focus {
    outline: none;
    box-shadow: 0 0 0 .2rem rgba(${props => props.theme.rgb.red}, ${props => props.theme.rgb.green}, ${props => props.theme.rgb.blue}, .3) !important;
    border: 1px solid ${props => props.theme.color};
  }
`;

export const ThemedHeaders = {
  H1: styled.h1`color: ${props => props.theme.color}`,
  H2: styled.h2`color: ${props => props.theme.color}`,
  H3: styled.h3`color: ${props => props.theme.color}`,
  H4: styled.h4`color: ${props => props.theme.color}`,
  H5: styled.h5`color: ${props => props.theme.color}`,
  H6: styled.h6`color: ${props => props.theme.color}`,
}

export const ThemedIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.color};
`;

export const ThemedListGroupItem = styled(ListGroup.Item)`
  &.active {
    border-color: ${props => props.theme.color} !important;
    background: ${props => props.theme.color} !important;
    color: var(--white);
  }
`;
  
export const ThemedLogo = styled.div`
  display: inline-block;
  background-image: url('${props => props.theme.logo}');
  background-size: cover;
  background-position: center;
  height: 150px;
  width: 150px;
`;

export const ThemedSpinner = styled(Spinner)`
  color: ${props => props.theme.color};
`;